<template>
  <Layout el="hm fixed">
    <Header>
      <List el="column-between xsm" class="v-center">
        <List el="column xsm" class="v-center">
          <Icon name="arrow_back" @click="close" />
          <img src="../assets/logo/alphaleaf_logo_brandmark_color.svg" id="mobileLogo" alt="logo" el="logo">
          <Text el="h4">Type fu</Text>
        </List>
        <List el="column xsm" class="v-center">
          <!-- <Icon name="file_download" @click="close" /> -->
        </List>
      </List>

      <!-- <List el="xsm" style="padding:32px;">
        <List el="column-between" class="v-center">
          <Text el="h3" v-if="file != null">{{ file.label }}</Text>
          <Text el="h3" v-else>File does not exist</Text>
          <Icon name="close" @click="close" />
        </List>
      </List> -->
    </Header>
    <main el="main">
      <div>
        <iframe src="https://type-fu.com/app" frameborder="0"></iframe>
      </div>
    </main>
  </Layout>
</template>
<script>
// let includes = require("lodash/includes");
import router from "../router";
// import { db } from "@/firebase";
// import { doc, getDoc } from "firebase/firestore";
import Icon from "@/components/btn/Icon.vue";
// import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
    };
  },
  watch: {
    "$route.params.path": function () {
      this.getFile();
    },
  },
  methods: {
    close() {
      // this.section.collapse = true;
      router.go(-1);
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
[el="header"] {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
}
[el="main"] {
  --bg-color: rgba(0, 0, 0, 0.85);
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}
.v-center {
  display: grid;
  align-items: center;
}
iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}
#mobileLogo {
  height: 35px;
}
.logo-symbol {
  fill: var(--color-ocean);
}
</style>
